import React from "react";
import {ApiGenericPageData, ApiNavbarOption, ApiStreamer, ApiStreamerCategory} from "#/src/definitions/ApiDefinitions";
import {usePageContext} from "vike-react/usePageContext";
import {NavbarLivestreamerDetails} from "#/src/components/navbar/NavbarLivestreamerDetails";
import {useData} from "vike-react/useData";
import {onMobileDropdownClick} from "#/src/utils/dropdown-mobile-utils";
import {Sort} from "#/src/utils/sort-utils";
import {DataWrapped} from "#/src/definitions/AppDefinitions";

interface Props {
  option: ApiNavbarOption
}

export function LiveNavbarRoute({option}: Props) {
  const {data} = useData() as DataWrapped<ApiGenericPageData>;
  const {urlPathname} = usePageContext();

  function render() {
    return <li key={option.id} className="nav-item dropdown dropdown-megamenu">
      <a className={`nav-link dropdown-toggle ${urlPathname.startsWith("/live") ? " active" : ""}`} href={"/live"} role="button" data-bs-toggle="dropdown"
         aria-expanded="false" aria-haspopup="true" id="navbarDropdown" onClick={onMobileDropdownClick}>
        {option.name}
      </a>
      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
        <div className="row">{renderCategories()}</div>
      </ul>
    </li>;
  }

  function renderCategories() {
    return Sort.byDisplayPriority(data.navbarData.streamerCategories)
      .slice(0, 3)
      .map((category: ApiStreamerCategory) => {
        return <div key={category.id} className="col-xl-4">
          <a href={`/live?category=${category.name}`} className="nav-livetitle">
            {category.name}
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 15L12.5 10L7.5 5" stroke="#EEF5F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </a>
          <ul className="nav-livelist">
            {Sort.byStreamerDisplay(
              data.navbarData.streamers
                .filter(s => s.category.id === category.id)
                .map(value => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value)
                .sort((a, b) => Number(b.isLive) - Number(a.isLive))
              ).slice(0, 3).map((streamer: ApiStreamer) => <NavbarLivestreamerDetails key={streamer.id} streamer={streamer}/>)}
          </ul>
        </div>
      });
  }

  return render();
}